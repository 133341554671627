import React from 'react'

function SuccessAdd() {
    return (
        <div className="page page--success-add">
            <h2> sucess add !! ... </h2> 
            <p>
                lol opératiobn réalisé avec succes !!! <br /> 
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque numquam nesciunt, consectetur magnam at assumenda, quae laudantium nulla architecto aliquam commodi esse inventore, a nemo sapiente. Minus reiciendis tempora atque?
            </p>
        </div>
    )
}

export default SuccessAdd
