import React from 'react'

function About() {
    return (
        <div className="page page--about">
                <h2> about page </h2>
                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt molestias ipsum nihil nemo. Eaque minus sed facilis provident eius rem similique optio autem harum? Blanditiis sit ratione voluptatibus excepturi dolores? </p>
        </div>
    )
}

export default About
